import { Asset } from "../models/Asset";
import { axiosInstance } from "./axios";

export async function updateAsset(pageId: string, assetId: string, changes: Asset, auth: string): Promise<void> {
  console.log(`if we were going to update an asset right now, it would have pageId ${pageId} and assetId ${assetId}, with data ${JSON.stringify(changes, null, '\t')}`)
  await axiosInstance.patch(`/pages/${pageId}/asset/${assetId}`, changes, {
    headers: {
      Authorization: auth,
    },
  });
}

export async function deleteAsset(pageId: string, assetId: string, auth: string): Promise<void> {
  await axiosInstance.delete(`/pages/${pageId}/asset/${assetId}`, {
    headers: {
      Authorization: auth,
    },
  });
}