import { Dictionary } from "lodash";
import { Col, Row } from "react-bootstrap";
import { deleteAsset } from "../api/assets";
import { Asset } from "../models/Asset";
import { MovableAsset } from "./assets/base/MovableAsset";
import { CardAsset } from "./assets/CardAsset";
import { CardGroupAsset } from "./assets/CardGroupAsset";
import { DroppableGridCell } from "./assets/cells/DroppableGridCell";
import { ContainerAsset } from "./assets/ContainerAsset";
import { HtmlAsset } from "./assets/HtmlAsset";
import { ImageAsset } from "./assets/ImageAsset";
import { PlaceholderAsset } from "./assets/PlaceholderAsset";
import { RichTextAsset } from "./assets/RichTextAsset";
import { TableAsset } from "./assets/TableAsset";
import { TextAsset } from "./assets/TextAsset";
import { PageEditMode } from "./PageComponent";

// assets that cannot appear in the grid - filter them out in case their coordinates are duplicates of a valid asset
// since we can only display one asset for a given coordinate pair
export const excludedAssetTypes = ['NavbarAsset', 'NavbarBrandAsset', 'NavbarLinksAsset', 'NavbarDropdownAsset'];
export function placeholderForMode(mode: PageEditMode, cellId?: string): JSX.Element | null {
  switch (mode) {
    case PageEditMode.Move:
    case PageEditMode.Create:
      return (
        <div style={{backgroundColor: 'green', minWidth: '100px', minHeight: '100px', opacity: '40%', borderRadius: '25px', border: '5px solid black'}}>
          <span className="align-middle" style={{color: 'white'}}>{`empty space (${cellId ?? 'unknown'})`}</span>
        </div>
      );
    default:
      return null;
  }
}
export function getAsset(definition: Asset, mode: PageEditMode, cellId?: string, pageId?: string, auth?: string): JSX.Element | null {
  let element: JSX.Element | null;
  switch(definition.kind) {
    case 'CardAsset':
      element = <CardAsset asset={definition}/>;
      break;
    case 'CardGroupAsset':
      element = <CardGroupAsset asset={definition}/>;
      break;
    case 'TextAsset':
      element = <TextAsset asset={definition}/>;
      break;
    case 'ImageAsset':
      element = <ImageAsset asset={definition}/>;
      break;
    case 'RichTextAsset':
      element = <RichTextAsset asset={definition}/>;
      break;
    case 'HtmlAsset':
      element = <HtmlAsset asset={definition}/>;
      break;
    case 'TableAsset':
      element = <TableAsset asset={definition}/>;
      break;
    case 'ContainerAsset':
      element = <ContainerAsset asset={definition} mode={mode}/>;
      break;
    default:
      element = (
        definition?.kind ? <PlaceholderAsset asset={definition}/> : placeholderForMode(mode, cellId)
      );
      break;
  }

  let adaptedMode = mode;
  if (definition.fromTemplate || definition.fromShared) {
    // these are immutable for now
    adaptedMode = PageEditMode.None;
  }
  switch (adaptedMode) {
    case PageEditMode.Move:
      return <MovableAsset definitionId={definition.id!}>{element}</MovableAsset>;
    case PageEditMode.Delete:
      return (
        <div>
          {
            definition.id ? <button onClick={() => deleteSelectedAsset(pageId!, definition.id!, auth!)} type="button" className="btn btn-danger" aria-label="Close" style={{marginBottom:"5px"}}>Delete</button> : null
          }
          {
            element
          }
        </div>
      );
    default:
      return element;
  }
}

export function getCols(assetRow: Asset[], rowIndex: number, mode: PageEditMode, containerId?: string, pageId?: string, auth?: string): JSX.Element[] {
  const fullRow = getRowAssetsWithPlaceholders(assetRow, mode);
  return fullRow.map((asset, colIndex) => {
    let adaptedMode = mode;
    if (asset.fromTemplate || asset.fromShared) {
      // if this asset is from a template or shared, it is immutable (for now)
      adaptedMode = PageEditMode.None;
    }
    switch (adaptedMode) {
      case PageEditMode.Move:
        const cellId = `${colIndex},${rowIndex}${containerId ? `-${containerId}` : ''}`;
        return (
          <Col className="my-auto" style={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}>
            <DroppableGridCell cellId={cellId}>
              {getAsset(asset, mode, cellId)}
            </DroppableGridCell>
          </Col>
        );
      default:
        return (
          <Col style={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}>
            {getAsset(asset, mode, undefined, pageId, auth)}
          </Col>
        );
    }
    
  });
}

export function getRows(assets: Dictionary<Asset[]>, mode: PageEditMode, containerId?: string, pageId?: string, auth?: string): JSX.Element[] {
  const rows = Object.values(assets).map((entry, rowIndex) => {
    return (
      <Row style={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}>
        {
          getCols(entry, rowIndex, mode, containerId, pageId, auth)
        }
      </Row>
    );
  });
  if (mode === PageEditMode.Create || mode === PageEditMode.Move) {
    rows.push(
      <Row style={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}>
        {
          getCols([], Object.values(assets).length, mode, containerId)
        }
      </Row>
    );
  }
  return rows;
}

export function getRowAssetsWithPlaceholders(initialRow: Asset[], mode: PageEditMode): Asset[] {
  const row: Asset[] = [];
  const xMax = Math.max(...initialRow.map(asset => asset.x ?? 0));
  const filteredRow = initialRow.filter(asset => !excludedAssetTypes.includes(asset.kind!));
  for (let i = 0; i <= xMax; i++) {
    row.push(filteredRow.find(asset => asset.x === i) ?? {});
  }
  if (mode === PageEditMode.Create || mode === PageEditMode.Move) {
    // give flexibility to have an additional row space to put new assets in
    row.push({});
  }
  return row;
}

async function deleteSelectedAsset(pageId: string, assetId: string, auth: string): Promise<void> {
  await deleteAsset(pageId, assetId, auth);
  window.location.reload();
}