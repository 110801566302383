import React from "react";
import { Asset } from "../../models/Asset";

interface IProps {
  asset: Asset,
}

interface IState {}

export class TextAsset extends React.Component<IProps, IState> {
  render() {
    return (
      <p {...this.props.asset.styles} style={this.props.asset.substyles}>{this.props.asset.content}</p>
    );
  }
}