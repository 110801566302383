import { useDroppable } from "@dnd-kit/core";
import { ReactNode } from "react";

export function DroppableGridCell(props: { children: ReactNode, cellId: string }) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.cellId,
  });

  const style = {
    color: isOver ? 'green' : undefined,
  };
  
  return (
    <div ref={setNodeRef} style={style}>{props.children}</div>
  );
}