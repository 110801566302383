// standard - plain user
// contentadmin - manages pages
// admin - manages everything, including other users and their access (todo)
export enum UserLevel {
  Standard,
  ContentAdmin,
  Admin,
}

export interface UserData {
  email: string;
  firstName: string;
  lastName: string;
  validatedEmail?: boolean;
  userLevel: UserLevel;
}