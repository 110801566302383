import React from "react";
import { Container } from "react-bootstrap";
import { Asset } from "../../models/Asset";
import { Dictionary, groupBy } from "lodash";
import { getRows } from "../gridcommon";
import { PageEditMode } from "../PageComponent";

interface IProps {
  asset: Asset,
  mode: PageEditMode,
}

interface IState {}

export class ContainerAsset extends React.Component<IProps, IState> {
  getAssetsDictionary(): Dictionary<Asset[]> {
    const dict: Dictionary<Asset[]> = {};
    const combinedAssets = (this.props.asset.children ?? []);
    const yMax = Math.max(...combinedAssets.map(asset => asset.y ?? 0));
    const assetsByRow = groupBy(combinedAssets, asset => asset.y);
    for (let i = 0; i <= yMax; i++) {
      dict[`${i}`] = assetsByRow[`${i}`] ?? [];
    }
    return dict;
  }

  render() {
    const assetsByRow = this.getAssetsDictionary();
    return(
      <Container fluid>
        {
          getRows(assetsByRow, this.props.mode, this.props.asset.id)
        }
      </Container>
    );
  }
}