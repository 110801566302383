import React from "react";
import { Card } from "react-bootstrap";
import { Asset } from "../../../../models/Asset";
import { getCardChild } from "./utils/commoncardutils";

interface IProps {
  asset: Asset,
}

interface IState {}

export class CardImageOverlayAsset extends React.Component<IProps, IState> {
  render() {
    const sortedChildren = this.props.asset.children?.sort((a, b) => a.y! - b.y!);
    return (
      <Card.ImgOverlay {...this.props.asset.styles} style={this.props.asset.substyles}>
        {
          sortedChildren?.map(child => getCardChild(child))
        }
      </Card.ImgOverlay>
    );
  }
}