import { Page } from "../models/Page";
import { PageListEntry } from "../models/PageListEntry";
import { axiosInstance } from "./axios";

export async function listPages(): Promise<PageListEntry[]> {
  const response = await axiosInstance.get<PageListEntry[]>('/pages');
  return response.data;
}

export async function getPage(id: string): Promise<Page> {
  const response = await axiosInstance.get<Page>(`/pages/${id}`);
  return response.data;
}