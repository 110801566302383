import React from "react";
import { Card } from "react-bootstrap";
import { Asset } from "../../../../models/Asset";

interface IProps {
  asset: Asset,
}

interface IState {}

export class CardImageAsset extends React.Component<IProps, IState> {
  render() {
    return (
      <Card.Img {...this.props.asset.styles} style={this.props.asset.substyles} src={this.props.asset.content}/>
    );
  }
}