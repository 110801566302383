import React from "react";
import { Button, Form, Modal, Navbar } from "react-bootstrap";
import { UserData } from "../../../../models/UserData";
import Cookies from 'universal-cookie';

interface IProps {
  user?: UserData,
}
interface IState {
  showLoginModal: boolean,
  usernameInput?: string,
  passwordInput?: string,
}

export class UserBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showLoginModal: false
    };
    this.handleShowLoginModal = this.handleShowLoginModal.bind(this);
    this.handleCloseLoginModal = this.handleCloseLoginModal.bind(this);
    this.handleSetUsername = this.handleSetUsername.bind(this);
    this.handleSetPassword = this.handleSetPassword.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleShowLoginModal() {
    this.setState({
      showLoginModal: true
    });
  }

  handleCloseLoginModal() {
    this.setState({
      showLoginModal: false
    });
  }

  getUserBar(): JSX.Element {
    if (this.props.user) {
      return (
        <Navbar.Text>Hello, {this.props.user.firstName} {this.props.user.lastName}! <button className='link-button' onClick={this.handleLogout}>(sign out)</button></Navbar.Text>
      );
    }
    else {
      return (
        <Navbar.Text>Hello, Guest! (<button className='link-button' onClick={this.handleShowLoginModal}>sign in</button> | <button className='link-button'>sign up</button>)</Navbar.Text>
      );
    }
  }

  handleLogin(): void {
    const cookies = new Cookies();
    cookies.set('username', this.state.usernameInput);
    cookies.set('password', this.state.passwordInput);
    window.location.reload();
  }

  handleLogout(): void {
    const cookies = new Cookies();
    cookies.remove('username');
    cookies.remove('password');
    window.location.reload();
  }

  handleSetUsername(event: any): void {
    this.setState({ usernameInput: event.target.value });
  }

  handleSetPassword(event: any): void {
    this.setState({ passwordInput: event.target.value });
  }

  render() {
    return (
      <div>
        {
          this.getUserBar()
        }
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showLoginModal} onHide={this.handleCloseLoginModal}>
          <Modal.Header closeButton>
            <Modal.Title>Sign In</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="loginForm.Username">
                <Form.Label>Username (usually an email address)</Form.Label>
                <Form.Control type="email" placeholder="name@example.com" onChange={this.handleSetUsername} autoFocus/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="loginForm.Password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={this.handleSetPassword}/>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" onClick={this.handleLogin}>
              Sign In
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}