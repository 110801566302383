import React from "react";
import { Button, ButtonGroup, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Asset } from "../models/Asset";
import { PageListEntry } from "../models/PageListEntry";
import { UserData, UserLevel } from "../models/UserData";
import { UserBar } from "./assets/children/navbar/UserBar";
import { PageEditMode } from "./PageComponent";

interface IProps {
  pages: PageListEntry[],
  asset: Asset,
  onSetEditMode: (mode: PageEditMode) => void,
  editMode: PageEditMode,
  user?: UserData,
}
interface IState {}

export class NavbarComponent extends React.Component<IProps, IState> {

  urlForPage(id: string): string {
    return `${window.location.protocol}//${window.location.host}?page=${id}`;
  }

  definitionToAsset(definition: Asset): JSX.Element | undefined {
    switch (definition.kind) {
      case 'NavbarBrandAsset':
        // support html content in this once we actually know what we're doing
        return <Navbar.Brand href={definition.content} {...definition.styles}>{definition.friendlyName}</Navbar.Brand>;
      case 'NavbarLinksAsset':
        return (
          <Nav className='me-auto' {...definition.styles}>
            {
              this.props.pages.map(page => <Nav.Link href={this.urlForPage(page.id)}>{page.name}</Nav.Link>)
            }
          </Nav>
        );
      case 'NavbarDropdownAsset':
        return (
          <NavDropdown title={definition.friendlyName} {...definition.styles}>
            {
              this.props.pages.map(page => <NavDropdown.Item href={this.urlForPage(page.id)}>{page.name}</NavDropdown.Item>)
            }
          </NavDropdown>
        );
    }
  }

  pageAdminControls(): JSX.Element | undefined {
    return (this.props.user?.userLevel! >= UserLevel.ContentAdmin ? (
      <ButtonGroup className="ms-auto">
        <Button className={this.props.editMode === PageEditMode.Create ? "active" : ""} variant="success" onClick={() => this.props.onSetEditMode(PageEditMode.Create)}>Create/Edit</Button>
        <Button className={this.props.editMode === PageEditMode.Move ? "active" : ""} variant="warning" onClick={() => this.props.onSetEditMode(PageEditMode.Move)}>Move</Button>
        <Button className={this.props.editMode === PageEditMode.Delete ? "active" : ""} variant="danger" onClick={() => this.props.onSetEditMode(PageEditMode.Delete)}>Delete</Button>
        {
          this.props.editMode > PageEditMode.None ? (
            <Button variant="primary" onClick={() => this.props.onSetEditMode(PageEditMode.None)}>X</Button>
          ) : null
        }
      </ButtonGroup>
    ) : undefined);
  }

  render() {
    const sortedChildren = (this.props.asset?.children?.sort((a, b) => a.x! - b.x!) ?? []) as Asset[];
    return(
      this.props.asset?.kind ? <Navbar {...this.props.asset.styles}>
        <Container fluid>
          <Navbar.Collapse id='responsive-navbar-nav'>
            {
              sortedChildren.map(child => this.definitionToAsset(child))
            }
            {
              this.pageAdminControls()
            }
          </Navbar.Collapse>
          <Navbar.Toggle aria-controls='basic-navbar-nav'/>
          <Navbar.Collapse className="justify-content-end">
            <UserBar user={this.props.user}/>
          </Navbar.Collapse>
        </Container>
      </Navbar> : null
    );
  }
}