import React from "react";
import { Asset } from "../../../../models/Asset";

interface IProps {
  asset: Asset,
  inHeader: boolean,
}

interface IState {}

export class TableColumnAsset extends React.Component<IProps, IState> {
  render() {
    if (this.props.inHeader) {
      return (
        <th {...this.props.asset.styles} style={this.props.asset.substyles}>{this.props.asset.content}</th>
      );
    }
    else {
      return (
        <td {...this.props.asset.styles} style={this.props.asset.substyles}>{this.props.asset.content}</td>
      );
    }
  }
}