import React from "react";
import { getPage, listPages } from "../api/pages";
import { Page } from "../models/Page";
import { PageListEntry } from "../models/PageListEntry";
import { UserData } from "../models/UserData";
import { AssetGrid } from "./AssetGrid";
import { NavbarComponent } from "./NavbarComponent";
import Cookies from 'universal-cookie';
import { authUser } from "../api/users";

export enum PageEditMode {
  None,
  Create,
  Move,
  Delete,
}

interface IProps {}
interface IState {
  pages: PageListEntry[],
  page: Page,
  editMode: PageEditMode,
  user?: UserData,
  auth?: string,
}

export class PageComponent extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      pages: [],
      page: {},
      editMode: PageEditMode.None,
    };
    this.setPageEditMode = this.setPageEditMode.bind(this);
  }

  setPageEditMode(mode: PageEditMode) {
    this.setState({ editMode: mode });
    const cookies = new Cookies();
    cookies.set('editMode', mode);
  }
  
  async componentDidMount(): Promise<void> {
    const queryParams = new URLSearchParams(window.location.search);
    const pageId = queryParams.get('page');
    const pages = await listPages();
    this.setState({ pages });
    const rootPage = pages.find(page => page.isRoot);
    const page = await getPage(pageId ?? rootPage?.id!);
    this.setState({ page });
    const cookies = new Cookies();
    const username = cookies.get('username');
    const password = cookies.get('password');
    if (username && password) {
      const user = await authUser(username, password);
      if (user) {
        this.setState({ user: user[0], auth: user[1] });
      }
    }
  }

  render() {
    return(
      <div>
        <NavbarComponent
          user={this.state.user}
          pages={this.state.pages}
          asset={(this.state.page.assets?.concat(this.state.page?.sharedAssets ?? []).find(asset => asset.kind === 'NavbarAsset')) ?? {}}
          onSetEditMode={this.setPageEditMode}
          editMode={this.state.editMode}
        />
        <AssetGrid page={this.state.page} mode={this.state.editMode} auth={this.state.auth}/>
      </div>
    );
  }
}