import { Container } from "react-bootstrap";
import { Asset } from "../models/Asset";
import { Page } from "../models/Page";
import { Dictionary, groupBy } from "lodash";
import { getRows } from "./gridcommon";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { updateAsset } from "../api/assets";
import { PageEditMode } from "./PageComponent";

let page: Page;
let auth: string | undefined;

function getAssetsDictionary(page: Page): Dictionary<Asset[]> {
  const dict: Dictionary<Asset[]> = {};
  const combinedAssets = (page.assets ?? []).concat(page.sharedAssets?.map(asset => {
    asset.fromShared = true;
    return asset;
  }) ?? []);
  const yMax = Math.max(...combinedAssets.map(asset => asset.y ?? 0));
  const assetsByRow = groupBy(combinedAssets, asset => asset.y);
  for (let i = 0; i <= yMax; i++) {
    dict[`${i}`] = assetsByRow[`${i}`] ?? [];
  }
  return dict;
}

async function onDragEnd(event: DragEndEvent) {
  const destAssetCoords = event?.over?.id.toString().split(',');
  const destAsset = page.assets?.find(asset => asset.x?.toString() === destAssetCoords?.[0] && asset.y?.toString() === destAssetCoords?.[1]);
  const sourceAsset = page.assets?.find(asset => asset.id === event.active.id);
  console.log(`just dragged asset with id ${event.active.id} from position ${sourceAsset?.x},${sourceAsset?.y} to position ${event?.over?.id}, occupied by asset with id ${destAsset?.id}`);
  if (sourceAsset && event.over) {
    const destSpaceCoords = event.over.id.toString().split(',');
    // move the source asset into the destination space
    await updateAsset(page.id!, sourceAsset.id!, { x: parseInt(destSpaceCoords[0]), y: parseInt(destSpaceCoords[1]) }, auth!);
    if (destAsset) {
      // move the destination asset (if any) into the source space
      await updateAsset(page.id!, destAsset.id!, { x: sourceAsset.x, y: sourceAsset.y }, auth!);
    }
    // reload the updated page
    window.location.reload();
  }
}

export function getGridForMode(mode: PageEditMode, assetsByRow: Dictionary<Asset[]>): JSX.Element {
  switch (mode) {
    case PageEditMode.Move:
      return (
        <DndContext onDragEnd={onDragEnd}>
          <Container fluid>
            {
              getRows(assetsByRow, mode)
            }
          </Container>
        </DndContext>
      );
    default:
      return (
        <Container fluid>
          {
            getRows(assetsByRow, mode, undefined, page.id, auth)
          }
        </Container>
      );
  }
}

export function AssetGrid(props: { page: Page, mode: PageEditMode, auth?: string }): JSX.Element {
  page = props.page;
  auth = props.auth;
  const assetsByRow = getAssetsDictionary(props.page);
  return getGridForMode(props.mode, assetsByRow);
}