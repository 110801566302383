import React from "react";
import { Asset } from "../../models/Asset";

interface IProps {
  asset: Asset,
}

interface IState {}

export class ImageAsset extends React.Component<IProps, IState> {
  render() {
    return (
      <img alt={this.props.asset.friendlyName} src={this.props.asset.content} {...this.props.asset.styles} style={this.props.asset.substyles}/>
    );
  }
}