import { UserData } from "../models/UserData";
import { axiosInstance } from "./axios";

export async function authUser(username: string, password: string): Promise<[UserData, string] | null> {
  try {
    const response = await axiosInstance.get<UserData>('/users/authenticate', {
      auth: {
        username,
        password,
      }
    });
    return [response.data, response.headers['authorization']];
  } catch {
    return null;
  }
}