import { Asset } from "../../../../models/Asset";
import { TableRowAsset } from "./TableRowAsset";

export function getTableCommonChild(child: Asset, inHeader: boolean) {
  switch (child.kind) {
    case 'TableRowAsset':
      return <TableRowAsset asset={child} inHeader={inHeader}/>
    default:
      return null;
  }
}