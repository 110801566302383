import React from "react";
import { Table } from "react-bootstrap";
import { Asset } from "../../models/Asset";
import { TableBodyAsset } from "./children/table/TableBodyAsset";
import { TableHeaderAsset } from "./children/table/TableHeaderAsset";

interface IProps {
  asset: Asset,
}

interface IState {}

export class TableAsset extends React.Component<IProps, IState> {
  getTableChild(child: Asset) {
    switch (child.kind) {
      case 'TableHeaderAsset':
        return <TableHeaderAsset asset={child}/>
      case 'TableBodyAsset':
        return <TableBodyAsset asset={child}/>
      default:
        return null;
    }
  }

  render() {
    const sortedChildren = this.props.asset.children?.sort((a, b) => a.y! - b.y!);
    return (
      <Table {...this.props.asset.styles} style={this.props.asset.substyles}>
        {
          sortedChildren?.map(child => this.getTableChild(child))
        }
      </Table>
    );
  }
}