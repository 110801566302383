import React from "react";
import { Asset } from "../../../../models/Asset";
import { getTableCommonChild } from "./common";

interface IProps {
  asset: Asset,
}

interface IState {}

export class TableBodyAsset extends React.Component<IProps, IState> {
  render() {
    const sortedChildren = this.props.asset.children?.sort((a, b) => a.y! - b.y!);
    return (
      <tbody {...this.props.asset.styles} style={this.props.asset.substyles}>
        {
          sortedChildren?.map(child => getTableCommonChild(child, false))
        }
      </tbody>
    );
  }
}