import React from "react";
import { Card } from "react-bootstrap";
import { Asset } from "../../../../models/Asset";

interface IProps {
  asset: Asset,
}

interface IState {}

export class CardFooterAsset extends React.Component<IProps, IState> {
  render() {
    return (
      <Card.Footer {...this.props.asset.styles} style={this.props.asset.substyles}>{this.props.asset.content}</Card.Footer>
    );
  }
}