import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PageComponent } from './components/PageComponent';

function App() {
  return (
    <div className="App">
      <PageComponent/>
    </div>
  );
}

export default App;
