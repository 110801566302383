import React from "react";
import { Card } from "react-bootstrap";
import { Asset } from "../../models/Asset";

interface IProps {
  asset: Asset,
}

interface IState {}

export class PlaceholderAsset extends React.Component<IProps, IState> {
  render() {
    return (
      <Card>
        <Card.Title>{this.props.asset.friendlyName}</Card.Title>
        <Card.Subtitle>{this.props.asset.kind}</Card.Subtitle>
        <Card.Body>{this.props.asset.content}</Card.Body>
      </Card>
    );
  }
}