import React from "react";
import { CardGroup } from "react-bootstrap";
import { Asset } from "../../models/Asset";
import { CardAsset } from "./CardAsset";

interface IProps {
  asset: Asset,
}

interface IState {}

export class CardGroupAsset extends React.Component<IProps, IState> {
  getCardGroupChild(child: Asset) {
    switch (child.kind) {
      case 'CardAsset':
        return <CardAsset asset={child}></CardAsset>
      default:
        return null;
    }
  }

  render() {
    const sortedChildren = this.props.asset.children?.sort((a, b) => a.y! - b.y!);
    return (
      <CardGroup {...this.props.asset.styles} style={this.props.asset.substyles}>
        {
          sortedChildren?.map(child => this.getCardGroupChild(child))
        }
      </CardGroup>
    );
  }
}