import React from "react";
import { Asset } from "../../../../models/Asset";
import { TableColumnAsset } from "./TableColumnAsset";

interface IProps {
  asset: Asset,
  inHeader: boolean,
}

interface IState {}

export class TableRowAsset extends React.Component<IProps, IState> {
  getTableRowChild(child: Asset) {
    switch (child.kind) {
      case 'TableColumnAsset':
        return <TableColumnAsset asset={child} inHeader={this.props.inHeader}/>
      default:
        return null;
    }
  }
  render() {
    const sortedChildren = this.props.asset.children?.sort((a, b) => a.x! - b.x!);
    return (
      <tr {...this.props.asset.styles} style={this.props.asset.substyles}>
        {
          sortedChildren?.map(child => this.getTableRowChild(child))
        }
      </tr>
    );
  }
}