import React from "react";
import { Asset } from "../../models/Asset";

interface IProps {
  asset: Asset,
}

interface IState {}

export class HtmlAsset extends React.Component<IProps, IState> {
  render() {
    return (
      <div dangerouslySetInnerHTML={{__html: this.props.asset.content!}}/>
    );
  }
}