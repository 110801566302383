import { useDraggable } from "@dnd-kit/core";
import { ReactNode } from "react";

export function MovableAsset(props: { children: ReactNode, definitionId: string }) {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: props.definitionId,
  });

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;
  
  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </div>
  );
}