import { Asset } from "../../../../../models/Asset"
import { CardBodyAsset } from "../CardBodyAsset"
import { CardFooterAsset } from "../CardFooterAsset"
import { CardHeaderAsset } from "../CardHeaderAsset"
import { CardImageAsset } from "../CardImageAsset"
import { CardImageOverlayAsset } from "../CardImageOverlayAsset"
import { CardLinkAsset } from "../CardLinkAsset"
import { CardSubtitleAsset } from "../CardSubtitleAsset"
import { CardTextAsset } from "../CardTextAsset"
import { CardTitleAsset } from "../CardTitleAsset"

export function getCardChild(child: Asset) {
  switch (child.kind) {
    case 'CardHeaderAsset':
      return <CardHeaderAsset asset={child}></CardHeaderAsset>
    case 'CardBodyAsset':
      return <CardBodyAsset asset={child}></CardBodyAsset>
    case 'CardFooterAsset':
      return <CardFooterAsset asset={child}></CardFooterAsset>
    case 'CardImageAsset':
      return <CardImageAsset asset={child}></CardImageAsset>
    case 'CardTitleAsset':
      return <CardTitleAsset asset={child}></CardTitleAsset>
    case 'CardSubtitleAsset':
      return <CardSubtitleAsset asset={child}></CardSubtitleAsset>
    case 'CardImageOverlayAsset':
      return <CardImageOverlayAsset asset={child}></CardImageOverlayAsset>
    case 'CardTextAsset':
      return <CardTextAsset asset={child}></CardTextAsset>
    case 'CardLinkAsset':
      return <CardLinkAsset asset={child}></CardLinkAsset>
    default:
      return null;
  }
}